:root {
  --border-style: 4px double gray;
  --footer-height: 15em;
  --footer-minimise-offset: 2.5em;
  --footer-content-background: #0008;
}

@media screen and (max-width: 890px) {
  :root {
    --footer-height: 15em;
    --footer-minimise-offset: 3em;
  }
}

html,
body,
.App {
  height: 100vh;
  overflow: hidden;
  text-align: center;
  background-color: black;
}

p {
  font-size: 14px;
}

p,
h5,
h6 {
  font-family: "Sarpanch", sans-serif;
  text-align: left;
  color: white;
  margin: 0;
}

h1,
h2,
h3,
h4 {
  font-family: "Bruno Ace", sans-serif;
  text-align: left;
  text-transform: capitalize;
  color: white;
  margin: 0;
}

h1 {
  font-size: 2em;
}

h1.title {
  margin-left: 12px;
}

h5 {
  font-size: 1.2em;
  line-height: 2;
}

input,
label,
button {
  color: white !important;
}

label {
  pointer-events: none;
  font-size: 1em;
  font-family: "Sarpanch", sans-serif;
}

input[type="range"] {
  all: unset;
  border: var(--border-style);
  border-radius: 6px;
  height: 6px;
}

button,
.button {
  all: unset;
  font-size: 0.9em;
  font-family: "Sarpanch", sans-serif;
  border: var(--border-style);
  border-radius: 6px;
  padding: 4px 2em;
  /* min-width: 12em; */
  text-align: center;
  text-transform: uppercase;
  background-color: #0003;
}

.empty-button {
  all: unset;
  border-radius: 14px;
}

.active,
button:active {
  background: linear-gradient(180deg, #0e68c2 45.45%, rgba(0, 0, 0, 0.53) 100%);

  transform: scale(1.1);
  z-index: 23 !important;
  margin-top: 0px !important;
}

.down-arrow {
  position: relative;
  animation: Float 1s ease 1s infinite both alternate;
}

a {
  color: white;
}

nav {
  display: flex;
  align-items: baseline;
  position: absolute;
  z-index: 12;
  top: 0;
  left: 0;
  margin: 2em;
  width: -webkit-fill-available;
}

.border-box {
  font-size: 1.5em;
  border: var(--border-style);
  border-radius: 14px;
  padding: 16px;
  height: fit-content;
}

footer {
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  left: 0;
  z-index: 12;
  bottom: 0;
  font-size: 0.75em;
  width: 100vw;
  height: var(--footer-height);
  background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
}

.menu-tabs {
  display: flex;
  text-align: left;
  padding-left: 2em;
  padding-top: 2px;
}

.menu-tabs button {
  position: relative;
  margin-right: -6px;
  font-family: "Bruno Ace", sans-serif;
  height: 2em;
  border-radius: 12px 12px 0 0;
  border-bottom: none;
  margin-top: 0px;
  background-color: var(--footer-content-background);
  transition: margin-top 0.5s;
}

.menu-tabs button:first-of-type {
  z-index: 3;
}

.menu-tabs button:nth-of-type(2) {
  z-index: 2;
}

.menu-tabs > button:last-of-type::after {
  content: "";
  width: 100vw;
  border-bottom: var(--border-style);
  display: block;
  position: absolute;
  left: calc(100% + 3px);
  bottom: 0;
}

.menu-tabs > button:first-of-type::before {
  content: "";
  width: 80vw;
  border-bottom: var(--border-style);
  display: block;
  position: absolute;
  right: calc(100% + 3px);
  bottom: 0;
}

.footer-menu {
  position: relative;
  left: 0;
  display: flex;
  width: 200%;
  transition: left 0.8s;
  overflow: scroll;
  height: 100%;
  padding: 2em 0;
  background-color: var(--footer-content-background);
  backdrop-filter: blur(8px);
}

.footer-menu.menu--info {
  left: 0%;
}

.footer-menu.menu--controls {
  left: -100%;
}

.footer-menu > div {
  width: 100%;
  margin: 0 2em;
}

.bottom-slider {
  position: relative;
  display: flex;
  gap: 12px;
}

.bottom-slider.controls {
  flex-direction: column;
  align-items: center;
}

.bottom-slider p {
  margin: 0;
  padding-bottom: 2em;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.bottom-slider::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bottom-slider {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.more {
  top: -20px;
}
.less {
  bottom: 0px;
}

.manipulators {
  position: relative;
  display: flex;
  width: 100%;
  align-items: flex-start;
  height: 100%;
  gap: 12px;
  justify-content: space-evenly;
}

.scale {
  display: flex;
  gap: 12px;
}

.view {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 8px;
  width: 100%;
}

.view .button {
  width: min(50%, 170px);
}

.hidden {
  position: absolute;
  top: 0;
  right: 0;
  left: unset !important;
  /* height: inherit; */
  /* width: inherit; */
  margin: 0 0 auto auto;
}

.hidden > div {
  position: relative !important;
  height: inherit;
  width: inherit;
}

.container {
  /* position: absolute;
  top: 2em;
  right: 2em; */
  display: none;
}

.container > div {
  border: var(--border-style);
  border-radius: 14px;
  padding: 14px;
  background-color: rgba(0, 0, 0, 0.75);
}

.container h3 {
  padding-bottom: 0.75em;
}

.planet-labels {
  z-index: 1 !important;
  text-transform: capitalize;
}

.visible {
  animation: Reveal 1s forwards;
}

.pop-up-visible {
  display: block;
  animation: RevealPopUp 1s normal;
}

.hide {
  animation: Hide 1s forwards;
}

/* Animations */

@keyframes Reveal {
  from {
    bottom: calc(0em - (var(--footer-height) - var(--footer-minimise-offset)));
  }

  to {
    bottom: 0em;
  }
}

@keyframes RevealPopUp {
  from {
    right: -100%;
    opacity: 0;
  }

  to {
    right: 2em;
    opacity: 1;
  }
}

@keyframes Hide {
  from {
    bottom: 0em;
  }

  to {
    bottom: calc(0em - (var(--footer-height) - var(--footer-minimise-offset)));
  }
}

@keyframes Float {
  from {
    top: 0px;
  }

  to {
    top: 50px;
  }
}

/* Breakpoints */

@media screen and (max-width: 890px) {
  .border-box {
    font-size: 1em;
    padding: 12px;
  }

  .title {
    display: none;
  }

  .controls {
    align-items: center;
  }

  .manipulators {
    align-items: center;
    flex-direction: column;
  }

  .view {
    width: 100%;
    flex-direction: column;
  }

  .view .button {
    min-width: 30%;
    width: unset;
  }

  .menu-tabs {
    padding-left: 4px;
  }

  .bottom-slider.controls {
    flex-direction: column;
    align-items: center;
  }

  .bottom-slider {
    flex-direction: row;
    align-items: flex-start;
  }
}
